import { useMemo } from "react";
import { useCardItemImageContent } from "@tecnobit-srl/content-types-card-item-image/lazy";
import { useGalleryContent } from "@tecnobit-srl/content-types-gallery/lazy";
import { useContentPresetContent } from "@tecnobit-srl/content-types-content-preset/lazy";
import { useFormTermsContent } from "@tecnobit-srl/content-types-form-terms/lazy";
import { useFormPrivacyContent } from "@tecnobit-srl/content-types-form-privacy/lazy";
import { usePricingContent } from "@tecnobit-srl/content-types-pricing/lazy";
import { useFormFileUploadContent } from "@tecnobit-srl/content-types-form-file-upload/lazy";
import { useFormNumberContent } from "@tecnobit-srl/content-types-form-number/lazy";
import { useFormZipcodeContent } from "@tecnobit-srl/content-types-form-zipcode/lazy";
import { useFormNewsletterContent } from "@tecnobit-srl/content-types-form-newsletter/lazy";
import { useFormTextAreaContent } from "@tecnobit-srl/content-types-form-textarea/lazy";
import { useFormFieldsetContent } from "@tecnobit-srl/content-types-form-fieldset/lazy";
import { useFormRadioContent } from "@tecnobit-srl/content-types-form-radio/lazy";
import { useFormRadioGroupContent } from "@tecnobit-srl/content-types-form-radio-group/lazy";
import { useFormCheckContent } from "@tecnobit-srl/content-types-form-check/lazy";
import { useFormCheckGroupContent } from "@tecnobit-srl/content-types-form-check-group/lazy";
import { useFormPhoneContent } from "@tecnobit-srl/content-types-form-phone/lazy";
import { useCustomContentContent } from "@tecnobit-srl/content-types-custom-content/lazy";
import { useFormSelectContent } from "@tecnobit-srl/content-types-form-select/lazy";
import { useFormSubmitContent } from "@tecnobit-srl/content-types-form-submit/lazy";
import { useFeedIndexContent } from "@tecnobit-srl/content-types-feed-index/lazy";
import { useFormErrorContent } from "@tecnobit-srl/content-types-form-error/lazy";
import { useFormLabelContent } from "@tecnobit-srl/content-types-form-label/lazy";
import { useFormTextInputContent } from "@tecnobit-srl/content-types-form-text-input/lazy";
import { useFormHiddenContent } from "@tecnobit-srl/content-types-form-hidden/lazy";
import { useFormPresetContent } from "@tecnobit-srl/content-types-form-preset/lazy";
import { useFormStepContent } from "@tecnobit-srl/content-types-form-step/lazy";
import { useFormStepContainerContent } from "@tecnobit-srl/content-types-form-step-container/lazy";
import { useFeedYoutubeIndexContent } from "@tecnobit-srl/content-types-feed-youtube-index/lazy";
import { useBadgeContent } from "@tecnobit-srl/content-types-badge/lazy";
import { useCodeBlockContent } from "@tecnobit-srl/content-types-code-block/lazy";
import { usePolicyContent } from "@tecnobit-srl/content-types-policy/lazy";
import { useSocialShareContent } from "@tecnobit-srl/content-types-social-share/lazy";
import { useMenuHamburgerContent } from "@tecnobit-srl/content-types-menu-hamburger/lazy";
import { useMenuDividerContent } from "@tecnobit-srl/content-types-menu-divider/lazy";
import { useMenuSubgroupContent } from "@tecnobit-srl/content-types-menu-subgroup/lazy";
import { useMenuGroupColumnContent } from "@tecnobit-srl/content-types-menu-group-column/lazy";
import { useAccordionContent } from "@tecnobit-srl/content-types-accordion/lazy";
import { useFeedCarouselContent } from "@tecnobit-srl/content-types-feed-carousel/lazy";
import { useFeedYoutubeCarouselContent } from "@tecnobit-srl/content-types-feed-youtube-carousel/lazy";
import { useMenuLoginContent } from "@tecnobit-srl/content-types-menu-login/lazy";
import { useMenuGroupContent } from "@tecnobit-srl/content-types-menu-group/lazy";
import { useMenuLinkItemContent } from "@tecnobit-srl/content-types-menu-link-item/lazy";
import { useMenuButtonContent } from "@tecnobit-srl/content-types-menu-button/lazy";
import { useMenuPresetContent } from "@tecnobit-srl/content-types-menu-preset/lazy";
import { useMenuLogoContent } from "@tecnobit-srl/content-types-menu-logo/lazy";
import { useModalContent } from "@tecnobit-srl/content-types-modal/lazy";
import { useCarouselContent } from "@tecnobit-srl/content-types-carousel/lazy";
import { useCardContent } from "@tecnobit-srl/content-types-card/lazy";
import { useDownloadButtonContent } from "@tecnobit-srl/content-types-download-button/lazy";
import { useContainerContent } from "@tecnobit-srl/content-types-container/lazy";
import { useColumnContent } from "@tecnobit-srl/content-types-column/lazy";
import { useMarkdownContent } from "@tecnobit-srl/content-types-markdown/lazy";
import { useTitleContent } from "@tecnobit-srl/content-types-title/lazy";
import { useImageContent } from "@tecnobit-srl/content-types-image/lazy";
import { useButtonContent } from "@tecnobit-srl/content-types-button/lazy";
import { useGridContent } from "@tecnobit-srl/content-types-grid/lazy";
import { useYouTubeVideoContent } from "@tecnobit-srl/content-types-you-tube-video/lazy";
import { useIFrameContent } from "@tecnobit-srl/content-types-i-frame/lazy";
import { useBannerContent } from "@tecnobit-srl/content-types-banner/lazy";
const useCoreContentTypes = () => {
  const cardItemImage = useCardItemImageContent();
  const gallery = useGalleryContent();
  const contentPreset = useContentPresetContent();
  const formTerms = useFormTermsContent();
  const formPrivacy = useFormPrivacyContent();
  const pricing = usePricingContent();
  const formFileUpload = useFormFileUploadContent();
  const formNumber = useFormNumberContent();
  const formZipcode = useFormZipcodeContent();
  const formNewsletter = useFormNewsletterContent();
  const formTextArea = useFormTextAreaContent();
  const formFieldset = useFormFieldsetContent();
  const formRadio = useFormRadioContent();
  const formRadioGroup = useFormRadioGroupContent();
  const formCheck = useFormCheckContent();
  const formCheckGroup = useFormCheckGroupContent();
  const formPhone = useFormPhoneContent();
  const customContent = useCustomContentContent();
  const formSelect = useFormSelectContent();
  const formSubmit = useFormSubmitContent();
  const feedIndex = useFeedIndexContent();
  const formError = useFormErrorContent();
  const formLabel = useFormLabelContent();
  const formTextInput = useFormTextInputContent();
  const formHidden = useFormHiddenContent();
  const formPreset = useFormPresetContent();
  const formStep = useFormStepContent();
  const formStepContainer = useFormStepContainerContent();
  const feedYoutubeIndex = useFeedYoutubeIndexContent();
  const badge = useBadgeContent();
  const codeBlock = useCodeBlockContent();
  const policy = usePolicyContent();
  const socialShare = useSocialShareContent();
  const menuHamburger = useMenuHamburgerContent();
  const menuDivider = useMenuDividerContent();
  const menuSubgroup = useMenuSubgroupContent();
  const menuGroupColumn = useMenuGroupColumnContent();
  const accordion = useAccordionContent();
  const feedCarousel = useFeedCarouselContent();
  const feedYoutubeCarousel = useFeedYoutubeCarouselContent();
  const menuLogin = useMenuLoginContent();
  const menuGroup = useMenuGroupContent();
  const menuLinkItem = useMenuLinkItemContent();
  const menuButton = useMenuButtonContent();
  const menuPreset = useMenuPresetContent();
  const menuLogo = useMenuLogoContent();
  const modal = useModalContent();
  const carousel = useCarouselContent();
  const card = useCardContent();
  const downloadButton = useDownloadButtonContent();
  const container = useContainerContent();
  const column = useColumnContent();
  const markdown = useMarkdownContent();
  const title = useTitleContent();
  const image = useImageContent();
  const button = useButtonContent();
  const grid = useGridContent();
  const youTubeVideo = useYouTubeVideoContent();
  const iFrame = useIFrameContent();
  const banner = useBannerContent();
  return useMemo(
    () => ({
      ...cardItemImage,
      ...gallery,
      ...contentPreset,
      ...formTerms,
      ...formPrivacy,
      ...pricing,
      ...formFileUpload,
      ...formNumber,
      ...formZipcode,
      ...formNewsletter,
      ...formTextArea,
      ...formFieldset,
      ...formRadio,
      ...formRadioGroup,
      ...formCheck,
      ...formCheckGroup,
      ...formPhone,
      ...customContent,
      ...formSelect,
      ...formSubmit,
      ...feedIndex,
      ...formError,
      ...formLabel,
      ...formTextInput,
      ...formHidden,
      ...formPreset,
      ...formStep,
      ...formStepContainer,
      ...feedYoutubeIndex,
      ...badge,
      ...codeBlock,
      ...policy,
      ...socialShare,
      ...menuHamburger,
      ...menuDivider,
      ...menuSubgroup,
      ...menuGroupColumn,
      ...accordion,
      ...feedCarousel,
      ...feedYoutubeCarousel,
      ...menuLogin,
      ...menuGroup,
      ...menuLinkItem,
      ...menuButton,
      ...menuPreset,
      ...menuLogo,
      ...modal,
      ...carousel,
      ...card,
      ...downloadButton,
      ...container,
      ...column,
      ...markdown,
      ...title,
      ...image,
      ...button,
      ...grid,
      ...youTubeVideo,
      ...iFrame,
      ...banner
    }),
    [
      cardItemImage,
      gallery,
      contentPreset,
      formTerms,
      formPrivacy,
      pricing,
      formFileUpload,
      formNumber,
      formZipcode,
      formNewsletter,
      formTextArea,
      formFieldset,
      formRadio,
      formRadioGroup,
      formCheck,
      formCheckGroup,
      formPhone,
      customContent,
      formSelect,
      formSubmit,
      feedIndex,
      formError,
      formLabel,
      formTextInput,
      formHidden,
      formPreset,
      formStep,
      formStepContainer,
      feedYoutubeIndex,
      badge,
      codeBlock,
      policy,
      socialShare,
      menuHamburger,
      menuDivider,
      menuSubgroup,
      menuGroupColumn,
      accordion,
      feedCarousel,
      feedYoutubeCarousel,
      menuLogin,
      menuGroup,
      menuLinkItem,
      menuButton,
      menuPreset,
      menuLogo,
      modal,
      carousel,
      card,
      downloadButton,
      column,
      container,
      image,
      markdown,
      title,
      button,
      grid,
      youTubeVideo,
      iFrame,
      banner
    ]
  );
};
export {
  useCoreContentTypes
};
